<template>
  <div>
    <div id="pagePageview" class="mainContents">
      <UserNavSp :user="user" :page="'pageview'" />

      <UserTitle :user="user" />

      <UserNav :user="user" :page="'pageview'" />

      <div class="editPageviewArea">
        <div class="inner">
          <div class="titleArea">
            <h2>ページビュー一覧</h2>
            <div class="sortWrap">
              <div class="sort" @click="sortChange">
                <div class="selected">
                  {{ sortString }}
                </div>
                <ul
                  v-if="changeSort"
                  class="sortDropdown"
                  @mouseleave="closeSort($event)"
                >
                  <li @click="onSortChange($event, 1)">
                    投降日が新しい順
                  </li>
                  <li @click="onSortChange($event, 2)">
                    投降日が古い順
                  </li>
                  <li @click="onSortChange($event, 3)">
                    カテゴリー
                  </li>
                  <li @click="onSortChange($event, 4)">
                    ページビュー数が多い順
                  </li>
                  <li @click="onSortChange($event, 5)">
                    ページビュー数が少ない順
                  </li>
                </ul>
              </div>
              <div class="period pc tab">
                <div class="datepicker-container with-button">
                  <button id="btn-date" @click="triggerDatepicker">
                    {{ buttonLabel }}
                  </button>

                  <div
                    class="datepicker-wrap wrap-disp"
                    :class="pcCalendarDisplayClass"
                  >
                    <div class="datepicker-calendar">
                      <AirbnbStyleDatepicker
                        ref="datepicker"
                        v-model="datepickerPC"
                        :trigger-element-id="'datepicker-button-trigger'"
                        :mode="'range'"
                        :date-one="buttonDateOne"
                        :date-two="buttonDateTwo"
                        :fullscreen-mobile="true"
                        :months-to-show="3"
                        :mobile-header="''"
                        :close-after-select="true"
                        :trigger="trigger"
                        :inline="true"
                        @date-one-selected="updateDateOne($event)"
                        @date-two-selected="updateDateTwo($event)"
                        @closed="onClosed"
                        @cancelled="onCancelled"
                        @apply="onApply"
                        @previous-month="onMonthChange"
                        @next-month="onMonthChange"
                      />
                    </div>

                    <div class="datepicker-input">
                      <div class="datepicker-trigger">
                        <div class="date-box">
                          <span>開始日</span><input
                            id="datepicker-button-trigger"
                            type="text"
                            class="date-box-input"
                            :value="formatDates(buttonDateOne)"
                            @change="updateDateOne($event)"
                          >
                          -
                        </div>
                        <div class="date-box">
                          <span>終了日</span><input
                            id="datepicker-button-trigger2"
                            type="text"
                            class="date-box-input"
                            :value="formatDates(buttonDateTwo)"
                            @change="updateDateTwo($event)"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="period sp">
                <a @click="showModal">期間で絞り込み</a>
              </p>
            </div>
          </div>
          <table class="viewList">
            <tr class="header">
              <th>投稿日</th>
              <th>カテゴリー</th>
              <th>タイトル</th>
              <th>ページビュー数</th>
            </tr>
            <tr v-for="pageview in pageview.pageviews" :key="pageview.node.id">
              <td class="date">
                {{ displayDate(pageview.node.displayed) }}
              </td>
              <td class="category">
                {{ pageview.node.category }}
              </td>
              <td class="title">
                <a @click="linkToPage(pageview.node)">{{
                  pageview.node.title
                }}</a>
              </td>
              <td class="quantity">
                {{ numberWithCommas(pageview.node.view_count) }}
              </td>
            </tr>
          </table>

          <div class="buttonArea">
            <p>
              <button
                v-if="pageview.hasNextPage"
                type="submit"
                @click="getMorePageviews($event)"
              >
                もっと見る
              </button>
            </p>
            <!-- /.buttonArea -->
          </div>
        </div>
        <!-- /.editPageviewArea -->
      </div>

      <!-- /.mainContents -->
    </div>

    <!-- モーダル -->
    <modal name="input-period" :width="'90%'" @opened="onModalOpened">
      <div id="modalInputPeriod">
        <div class="modalInner">
          <dl>
            <dt>期間で絞り込み</dt>
            <dd>
              <div>
                <p class="title">
                  開始日
                </p>
                <div class="datepicker-container with-input">
                  <p class="inputWrap">
                    <input
                      id="input-date-from"
                      type="text"
                      name=""
                      :value="
                        inlineDateOne
                          ? formatDates(inlineDateOne)
                          : '選択してください'
                      "
                      readonly="readonly"
                      @click="triggerDatepickerSP1"
                    >
                  </p>
                  <div
                    class="datepicker-wrap wrap-disp-sp1"
                    :class="sp1CalendarDisplay"
                  >
                    <div class="datepicker-calendar">
                      <AirbnbStyleDatepicker
                        ref="datepickersp1"
                        v-model="datepickerSP1"
                        :trigger-element-id="'input-date-from'"
                        :mode="'single'"
                        :date-one="inlineDateOne"
                        :fullscreen-mobile="false"
                        :months-to-show="1"
                        :mobile-header="''"
                        :close-after-select="true"
                        :inline="true"
                        @date-one="inlineDateOne"
                        @date-one-selected="updateInlineDateOne($event)"
                        @selected="onClosed"
                        @previous-month="onMonthChange"
                        @next-month="onMonthChange"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p class="title">
                  終了日
                </p>
                <div class="datepicker-container with-input">
                  <p class="inputWrap">
                    <input
                      id="input-date-to"
                      type="text"
                      name=""
                      :value="
                        inlineDateTwo
                          ? formatDates(inlineDateTwo)
                          : '選択してください'
                      "
                      readonly="readonly"
                      @click="triggerDatepickerSP2"
                    >
                  </p>
                  <div
                    class="datepicker-wrap wrap-disp-sp2"
                    :class="sp2CalendarDisplay"
                  >
                    <div class="datepicker-calendar">
                      <AirbnbStyleDatepicker
                        ref="datepickersp2"
                        v-model="datepickerSP2"
                        :trigger-element-id="'input-date-to'"
                        :mode="'single'"
                        :date-one="inlineDateTwo"
                        :fullscreen-mobile="false"
                        :months-to-show="1"
                        :mobile-header="''"
                        :close-after-select="true"
                        :inline="true"
                        @date-one="inlineDateTwo"
                        @date-one-selected="updateInlineDateTwo($event)"
                        @selected="onClosed"
                        @previous-month="onMonthChange"
                        @next-month="onMonthChange"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <div class="buttonArea">
            <div class="inner">
              <p class="cancel" @click="hideModal">
                <a>キャンセル</a>
              </p>
              <p class="submit">
                <button type="submit" @click="onApplySp">
                  適用
                </button>
              </p>
            </div>
          </div>
          <p class="close">
            <a @click="hideModal" />
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import { mapState } from 'vuex'
import store from '@/store/index'
import { authComputed } from '@/store/helpers.js'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import { commonDate } from '@/helpers/date-format.js'

function formatDate (date) {
  var d = new Date(date)
  var month = '' + (d.getMonth() + 1)
  var day = '' + d.getDate()
  var year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('/')
}

function oneMonthAgo (date) {
  var d = new Date(date)
  var m = d.getMonth()
  d.setMonth(d.getMonth() - 1)
  if (d.getMonth() === m) d.setDate(0)

  var month = '' + (d.getMonth() + 1)
  var day = '' + d.getDate()
  var year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('/')
}

export default {
  components: {
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    u: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      changeSort: false,
      sortString: '並び替え',
      pageviews: [],
      datepickerPC: '',
      datepickerSP1: '',
      datepickerSP2: '',
      dateFormat: 'YYYY/MM/DD',
      inputDateOne: '',
      inputDateTwo: '',
      buttonDateOne: '',
      buttonDateTwo: '',
      inlineDateOne: '',
      inlineDateTwo: '',
      sundayDateOne: '',
      sundayFirst: false,
      alignRight: false,
      trigger: false,
      pcCalendarDisplay: null,
      sp1CalendarDisplay: null,
      sp2CalendarDisplay: null
    }
  },
  computed: {
    ...authComputed,
    ...mapState(['pageview']),
    buttonLabel: function () {
      return this.buttonDateOne && this.buttonDateTwo
        ? formatDate(this.buttonDateOne) +
            ' - ' +
            formatDate(this.buttonDateTwo)
        : '期間で絞り込み'
    },
    pcCalendarDisplayClass: function () {
      return this.pcCalendarDisplay
    }
  },
  created: function () {
    if (this.u) {
      this.user = this.u
    }
    this.buttonDateTwo = formatDate(new Date())
    this.buttonDateOne = oneMonthAgo(this.buttonDateTwo)

    this.inlineDateTwo = this.buttonDateTwo
    this.inlineDateOne = this.buttonDateOne

    this.getPagePageviews()
  },
  beforeDestroy () {},
  mounted () {},
  methods: {
    getPagePageviews () {
      store.dispatch('pageview/fetchPageviews', {
        first: 12,
        filter: {
          start_date: this.buttonDateOne,
          end_date: this.buttonDateTwo
        }
      })
    },
    getMorePageviews (event) {
      event.preventDefault()
      const args = {
        first: 12,
        after: this.pageview.endCursor,
        add: true,
        filter: {
          start_date: this.buttonDateOne,
          end_date: this.buttonDateTwo
        }
      }
      store.dispatch('pageview/fetchPageviews', args)
    },
    formatDates: function (dateOne, dateTwo) {
      var formattedDates = ''
      if (dateOne) {
        formattedDates = formatDate(dateOne)
      }
      if (dateTwo) {
        formattedDates += ' - ' + formatDate(dateTwo)
      }
      return formattedDates
    },
    onClosed: function () {
      this.trigger = false
    },
    onCancelled: function () {
      this.triggerDatepicker()
    },
    onApply: function () {
      this.triggerDatepicker()
      this.sortString = '並び替え'
      this.getPagePageviews()
    },
    onApplySp: function () {
      this.hideModal()
      this.sortString = '並び替え'
      this.getPagePageviews()
    },
    toggleAlign: function () {
      this.alignRight = !this.alignRight
    },
    triggerDatepicker: function () {
      this.trigger = !this.trigger
      this.pcCalendarDisplay =
        this.pcCalendarDisplay === 'js__on' ? null : 'js__on'
    },
    triggerDatepickerSP1: function () {
      this.sp2CalendarDisplay = null
      this.sp1CalendarDisplay =
        this.sp1CalendarDisplay === 'js__on' ? null : 'js__on'
    },
    triggerDatepickerSP2: function () {
      this.sp1CalendarDisplay = null
      this.sp2CalendarDisplay =
        this.sp2CalendarDisplay === 'js__on' ? null : 'js__on'
    },
    onMonthChange: function (dates) {},
    updateDateOne: function (val) {
      if (Date.parse(val)) {
        this.buttonDateOne = val
        this.inlineDateOne = val
      } else if (val.target && Date.parse(val.target.value)) {
        this.buttonDateOne = val.target.value
        this.inlineDateOne = val.target.value
      }
    },
    updateDateTwo: function (val) {
      if (Date.parse(val)) {
        this.buttonDateTwo = val
        this.inlineDateTwo = val
      } else if (val.target && Date.parse(val.target.value)) {
        this.buttonDateTwo = val.target.value
        this.inlineDateTwo = val.target.value
      }
    },
    updateInlineDateOne: function (val) {
      if (Date.parse(val)) {
        this.inlineDateOne = val
        this.buttonDateOne = val
        this.triggerDatepickerSP1()
      } else if (val.target && Date.parse(val.target.value)) {
        this.inlineDateOne = val.target.value
        this.buttonDateOne = val.target.value
        this.triggerDatepickerSP1()
      }
    },
    updateInlineDateTwo: function (val) {
      if (Date.parse(val)) {
        this.inlineDateTwo = val
        this.buttonDateTwo = val
        this.triggerDatepickerSP2()
      } else if (val.target && Date.parse(val.target.value)) {
        this.inlineDateOne = val.target.value
        this.buttonDateOne = val.target.value
        this.triggerDatepickerSP2()
      }
    },
    showModal () {
      this.$modal.show('input-period')
    },
    hideModal () {
      this.$modal.hide('input-period')
    },
    onModalOpened () {
      this.sp1CalendarDisplay = null
      this.sp2CalendarDisplay = null
    },
    sortChange () {
      this.changeSort = true
      this.trigger = false
      this.pcCalendarDisplay = null
    },
    closeSort (e) {
      e.stopPropagation()
      this.changeSort = false
    },
    onSortChange (e, val) {
      e.stopPropagation()
      switch (val) {
        case 1:
          this.sortString = '投降日が新しい順'
          store.dispatch('pageview/setSortOrder', 'displayed.desc')
          break
        case 2:
          this.sortString = '投降日が古い順'
          store.dispatch('pageview/setSortOrder', 'displayed.asc')
          break
        case 3:
          this.sortString = 'カテゴリー'
          store.dispatch('pageview/setSortOrder', 'category.asc')
          break
        case 4:
          this.sortString = 'ページビュー数が多い順'
          store.dispatch('pageview/setSortOrder', 'view_count.desc')
          break
        case 5:
          this.sortString = 'ページビュー数が少ない順'
          store.dispatch('pageview/setSortOrder', 'view_count.asc')
          break
        default:
          this.sortString = '並び替え'
      }
      store.dispatch('pageview/sortPageviews')
      this.changeSort = false
    },
    displayDate: function (date) {
      return commonDate(date, 'YYYY.MM.DD')
    },
    numberWithCommas: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    linkToPage: function (pageview) {
      if (pageview.category === '自分史') {
        this.$router.push({ name: 'user-history', params: { id: this.u.id } })
      } else if (pageview.category === '年表') {
        this.$router.push({
          name: 'user-timetable',
          params: { id: this.u.id }
        })
      } else if (pageview.category === 'エピソード') {
        this.$router.push({
          name: 'episode-show',
          params: { id: pageview.href }
        })
      }
    }
  }
}
</script>

<style scoped>
.datepicker-container .wrap-disp-sp2.js__on {
  z-index: 9999;
}
.v--modal-overlay .v--modal-box {
  overflow: visible;
}
</style>
